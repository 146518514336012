import type { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import g from 'src/constants/global';
import wrapper from 'src/redux';

const ErrorPageContainer = dynamic(() => import('src/containers/Custom/ErrorPage'));

const NotFoundPage = () => {
	const { imageUrl, title, description } = g.ERRORPAGE[404];
	return (
		<>
			<Head>
				<title>{title}</title>
			</Head>
			<ErrorPageContainer imageUrl={imageUrl} title={title} description={description} />
		</>
	);
};

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(() => () => {
	return {
		props: {
			isErrorPage: true,
		},
	};
});

export default NotFoundPage;
